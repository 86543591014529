import React, { useRef, useState } from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { Button, Form } from "react-bootstrap"
import Swal from "sweetalert2"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"

function Contact() {
  const data = useStaticQuery(graphql`
    query contactQuery {
      allStrapiContact {
        edges {
          node {
            contactSummary {
              description
              title
            }
            contactUs {
              companyAddress
              companyEmail
              companySocialMediaHandles
              emailIcon {
                publicURL
              }
              socialMediaIcon {
                publicURL
              }
              addressIcon {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  console.log(data)

  const { contactSummary, contactUs } = data.allStrapiContact.edges[0].node

  const inputRef = useRef("")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [disableBTN, setDisableBTN] = useState(false)
  const [error, setError] = React.useState("")

  const onSubmit = data => {
    fetch("https://formspree.io/f/xayajwob", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        if (response.status == 200) {
          setDisableBTN(true)
          inputRef.current.reset()
          Swal.fire({
            icon: "success",
            title: "Thanks",
            text: "We will reach out to you shortly",
          })
        } else {
          return response.json().then(data => setError(data.message))
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <StyledWrapper>
      <Layout>
        <SEO
          title="Contact"
          description="Want to know more? Get in touch."
          path="/contact"
        />
        <div className="container summary-container">
          <div className="row">
            <div className="col-12">
              <div className="summary-section d-flex justify-content-center flex-column">
                <div>
                  <p className="color-muddy summary-title">
                    {contactSummary.title}
                  </p>
                  <p className="summary-description">
                    {contactSummary.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container form-container">
          <div className="row">
            <div className="col-sm-6 col-12">
              <div className="page__title">Contact Form</div>

              <Form ref={inputRef} onSubmit={handleSubmit(onSubmit)}>
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  className="form__label"
                  {...register("full_name", { required: true })}
                  type="text"
                  placeholder="e.g. John Doe"
                />

                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  className="form__label"
                  {...register("company", { required: true })}
                  type="text"
                  placeholder="e.g. KKNT First Main Private Limited"
                />

                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className="form__label"
                  {...register("email", { required: true })}
                  type="email"
                  placeholder="e.g. yourid@domain.com"
                />

                <Form.Label>Subject</Form.Label>
                <Form.Control
                  className="form__label"
                  as="select"
                  name="subject"
                  {...register("subject", { required: true })}
                >
                  <option value="General">General</option>
                  <option value="New Business">New Business</option>
                  <option value="Collaboration">Collaboration</option>
                  <option value="Jobs">Jobs</option>
                </Form.Control>

                <Form.Label>Message</Form.Label>
                <br />
                <textarea
                  className="textarea form__label"
                  {...register("message", { required: true })}
                  placeholder="Tell us what you'd like to talk to us about."
                />
                <br />

                <Button
                  className="submit-form mt-sm-5 mt-3 p-2 "
                  type="submit"
                  disabled={disableBTN}
                >
                  {disableBTN == false ? "SUBMIT" : "SUBMITTED"}
                </Button>
              </Form>
            </div>
            <div className="col-sm-6 col-12 d-flex flex-column justify-content-center mt-5 mt-sm-0">
              <div className="address-section mx-auto">
                <p className="service-title pre-line">{contactUs.title}</p>
                <div className="d-flex margin-top-ad">
                  <img
                    className="img-fluid icons"
                    src={contactUs.emailIcon.publicURL}
                    alt=""
                  />
                  <p className="company-email pre-line">
                    {contactUs.companyEmail}
                  </p>
                </div>
                <div className="d-flex margin-top-ad">
                  <img
                    className="img-fluid icons"
                    src={contactUs.addressIcon.publicURL}
                    alt=""
                  />
                  <p className="company-address pre-line">
                    {contactUs.companyAddress}
                  </p>
                </div>
                <div className="d-flex margin-top-ad">
                  <img
                    className="img-fluid icons"
                    src={contactUs.socialMediaIcon.publicURL}
                    alt=""
                  />
                  <ReactMarkdown className="company-social-media-handles d-flex pre-line">
                    {contactUs.companySocialMediaHandles}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </StyledWrapper>
  )
}

export default Contact

const StyledWrapper = styled.div`
  .summary-container {
    padding-top: 100px;
  }

  .form-container {
    padding-bottom: 100px;
  }

  .submit-form {
    width: 177px;
    height: 50px;
    background: #9b51e0;
    border-radius: 5px;
    background-color: #9b51e0 !important;
    border: none;
  }

  .form__label{
    width:100%;
  }

  .icons {
    position: relative;
    top: 5px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 20px;
  }

  .summary-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.065em;
  }

  .summary-description {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
  }
  
  .page__title {
    padding-bottom: 0;
    margin-top: 40px;
    font-size:32px;
  }

  .margin-top-ad {
    margin-top: 30px;
  }

  input,
  select,
  textarea {
    width: 449px;
    background: #faf9f6;
    height: 50px;
  }

  textarea {
    height: 100px;
    padding: 10px;
  }

  .form-label {
    margin-top: 20px;
  }

  .address-section {
    width: 300px;
  }

  .company-social-media-handles p {
    font-size: 18px;
    line-height: 180.5%;
  }

  .company-address {
    font-size: 18px;
    line-height: 138%;
    letter-spacing: 0.05em;
  }

  .company-email {
    font-size: 18px;
    line-height: 127.5%;
  }

  @media(min-width:991px){
    .summary-container {
      padding-top: 200px;
    }

    .form__label{
      width:90%;
    }

    .summary-description{
      font-size: 48px;
      font-weight: 400;
      line-height: 65px;
    }

    .page__title {
      margin-top: 100px;
      font-size:36px;
    }
  }
`
